<template>
    <div class="admin-container">
        <h1>Webinars</h1>
         <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nom</th>
                        <th>Topic</th>                        
                        <th>Date début</th>
                        <th>Date fin</th>
                        <th>Participants</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in webinarList" :key="idx" :class="{complete: item.complete}">
                        <td>{{item.number}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.topic}}</td>
                        <td class="date-display">{{displayDate(item.date_start)}}</td>
                        <td class="date-display">{{displayDate(item.date_end)}}</td>
                        <td>{{item.participant}}</td>
                        <td>
                            <div class="actions-container">
                                <router-link v-if="item.display_question" :to="`/speaker/webinar-answers/${item.webinar_id}`" class="actions-btn questions-btn"></router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
         </div>
         <NotifError :errorTxt="errorTxt" v-if="errorTxt" v-on:closeModal="errorTxt = null"></NotifError>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError' 

export default {
    name : 'SpeakerWebinarList',
    components : {
        NotifError
    },
    data() {
        return {
            webinarList : [],
            errorTxt:null
        }
    },
    mounted() {
        let vm = this

        this.errorTxt = null
        
        axios
            .get(store.getters.getURL + "speakers/getWebinars.php?token=" + store.state.JWT_TOKEN + "&id=" + store.state.AUTH.user_id)
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.webinarList = response.data.res
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.errorTxt = 'An error has occured: ' + response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured: ' +  err
            });
    },
    methods : {
        displayDate(item) {
            const date = new Date(item);
            const formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            });

            return formattedDate;
        }
    }, 
}
</script>

<style lang="scss" scoped>
    .table-container{
        margin-top:40px;
    }

    .questions-btn {
        background: url('./../../assets/images/questions-orange.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .questions-btn:hover {
        background: url('./../../assets/images/questions-blue.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .date-display {
        min-width: 100px;
    }

    .admin-container tr.complete  {
        background: $secondaryColor;
    }

    .admin-container tr.complete td {
        background: $secondaryColor;
        color:#fff;
    }

    tr.complete .questions-btn:hover {
        background: url('./../../assets/images/questions-white.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }
</style>